import React from 'react'
import { Typography} from '@mui/material';
import {TreeItem,TreeView} from '@mui/lab'
import {ExpandMore,ChevronRight} from '@mui/icons-material'
import './index.scss'
export default function News() {


  const data={
    id:'root',
    name:'流程與辦法',
    children:[
      { 
        id:'1',
        name:'✦营业項目：按摩养生美容门店',
      },
      { 
        id:'2',
        name:'✦加盟方式：自願加盟',
      },
      { 
        id:'3',
        name:'✦商铺要求：至少500尺',
      },
      { 
        id:'4',
        name:'✦培训时长：15天',
      },
      { 
        id:'5',
        name:'✦装修时长：15天',
      },
      { 
        id:'6',
        name:'✦加盟成本：看店铺规模（3w起）',
      },
      { 
        id:'7',
        name:'✦合约期限：2年续签一次',
      },
    ]
  }

  const data2={
    id:'root2',
    name:'加盟资格',
    children:[
      { 
        id:'1',
        name:'✦对按摩美容养生行业有一定了解，有执照优先',
      },
      { 
        id:'2',
        name:'✦有坚定的创业致富意向，并且身体力行',
      },
      { 
        id:'3',
        name:'✦有志向投资一份长久经营的事业',
      },
      { 
        id:'4',
        name:'✦具有服务业应有的态度与观念',
      },
      { 
        id:'5',
        name:'✦能坚定的配合加盟总部的经营策略',
      },
     
    ]
  }
  const data3={
    id:'root3',
    name:'培训（15天）',
    children:[
      { 
        id:'1',
        name:'✦基础讲解按摩美容养生行业的相关知识（1天）',
      },
      { 
        id:'2',
        name:'✦实际操作训练（3天）',
      },
      { 
        id:'3',
        name:'✦软件操作培训（1天）',
      },
      { 
        id:'4',
        name:'✦店面行销管理，员工培训（5天）',
      },
      { 
        id:'5',
        name:'✦安排实际店面实习结业（5天）',
      },
     
    ]
  }
  const renderTree = (nodes) => (
    <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  return (

    <div className="news-container">
        <Typography variant="h4" id="news">
        代理资讯
        </Typography>
        <hr />
        <TreeView
      aria-label="rich object"
      expanded={['root']}
      defaultCollapseIcon={<ExpandMore />}
      defaultExpanded={['root']}
      defaultExpandIcon={<ChevronRight />}
      sx={{ height: 210, flexGrow: 1, maxWidth: 400,  }}
    >
      {renderTree(data)}
    </TreeView>
    <TreeView
      aria-label="rich object"
      defaultCollapseIcon={<ExpandMore />}
      defaultExpanded={['root2']}
      defaultExpandIcon={<ChevronRight />}
      sx={{ height: 210, flexGrow: 1, maxWidth: 400,  }}
    >
      {renderTree(data2)}
    </TreeView>
    <TreeView
      aria-label="rich object"
      defaultCollapseIcon={<ExpandMore />}
      defaultExpanded={['root3']}
      defaultExpandIcon={<ChevronRight />}
      sx={{ height: 210, flexGrow: 1, maxWidth: 400,  }}
    >
      {renderTree(data3)}
    </TreeView>
    </div>

  )
}
