import React from 'react'
import {Typography} from '@mui/material'
import './index.scss'
export default function Footer() {

  return (

    <div className="footer">

        <div>
            <Typography>
            Address: 4306 Main Street,Flushing,NY,11355
                </Typography>
          
        </div>

        <div>
        <Typography>
            Phone:<a href='tel:+13015200518'> 301-520-0518</a>
        </Typography>
          
        </div>

        <div>
        <Typography>
            Email:xingtianstudio@gmail.com
                </Typography>
        </div>

    </div>


  )
}
