import React,{useState} from 'react'
import axios from 'axios'
import {Typography,TextField,Button,CircularProgress} from '@mui/material'
import {Check} from '@mui/icons-material'
import './index.scss'

export default function Contact() {

    const url='https://pyrhomes.com'

    const [name,setName] = useState('')
    const [phone,setPhone] = useState('')
    const [other,setOther] = useState('')
    const [load,setLoad] = useState(false)
    const [check,setCheck] = useState(false)
  
    const handleChangeName = (e)=>{
        setName(e.target.value)

    }
  
    const handleChangePhone = (e)=>{
        setPhone(e.target.value)

    }

    const handleChangeOther = (e)=>{
        setOther(e.target.value)

    }

    const handleSubmit = async()=>{

        if(!name){
           alert('Missing Name')
            // setCheckName(true)
        }
        if(!phone){
            alert('Missing Phone')
           

        }
        if(name&&phone){
            setLoad(true)
          
    
            const data = await axios.post(`${url}/appointment/message`,{
                name,phone,message:other
            })
            if(data.data.success){
                setLoad(false)
                setCheck(true)
            }
        }
           
        
      }


  return (
    <div className="contact-container">
        <h2>联系我们</h2>
        <div>
        <TextField className="contactName"  variant='outlined' label='您的名字' value={name}  onChange={handleChangeName} />

        <TextField className="contactPhone"   variant='outlined' label='您的手机号' value={phone} onChange={handleChangePhone} />

        <TextField className="contactOther"  multiline variant='outlined' label='留言：' value={other} onChange={handleChangeOther} />
        </div>

        { load ? <CircularProgress color='primary'/>
: check ?            <Check />
:            <Button  onClick={handleSubmit} variant='contained' color='primary'>
    Submit
</Button>}

{
    check&& <Typography >
        稍后我们的团队会联系您！感谢您的信任！
    </Typography>
}
    </div> 
  )
}
