import Phone from './components/phone'
export default function Nav() {


    const navData =[
        {
            name:'企业简介',
            url:'#intro'
        },
        {  
            name:'特色与优势',
            url:'#good'  
        },
        {
            name:'营业内容',
            url:'#content'
        },
        {
            name:'代理资讯',
            url:'#news'
        },
        {
            name:'代理流程',
            url:'#target'
        },
        {
            name:'总部资源',
            url:'#resource'
        },
    ]

  


  return (

    <div >
      
      
    <Phone navData={navData}/>
    </div>
  )
}
