import React from 'react'
import { Typography } from '@mui/material';
import MapChart from './MapChart';
import './index.scss'
export default function Intro() {
  return (

    <div className="intro-container">

      <div><Typography variant="h4" id="intro">
        企业简介
        </Typography>

        <Typography variant="subtitle1" >
            2019年公司成立于纽约法拉盛
            <br />
            创始人Johnny在spa行业里摸爬滚打了十几年，总结了大量开店经验，于2019年开始着手创建连锁品牌Weiiz Spa。
            <br />
            目前在纽约上州，新泽西等地已陆续开设十多家分店。
        </Typography>
      </div>
      <div>  <MapChart className="map"/></div>
        
      
    </div>

  )
}
