import React from 'react'
import { Typography } from '@mui/material';
import Cards from './components/Cards'
import './index.scss'




const data = [
  { 
    title:'数据分析', 
    content:'我们会帮合伙人分析地理位置优势劣势，周边人口分布，人种构成，以及人均收入情况来定制开店计划', 
    image:'https://res.cloudinary.com/dlapk94rx/image/upload/v1648835049/ankespa/IMG/Weiiz_Spa_mkbyif.png'
  },
  { 
    title:'配套装修', 
    content:'我们有自己的装修团队以及特殊的装修设计材料，新店很快就能装修完毕开门营业', 
    image:'https://res.cloudinary.com/dlapk94rx/image/upload/v1648835048/ankespa/IMG/Weiiz_Spa_4_gydigj.png'
  },
  { 
    title:'配套设施', 
    content:'我们有自己设计定制的按摩床以及各类按摩精油及相关产品，有自己的物流团队', 
    image:'https://res.cloudinary.com/dlapk94rx/image/upload/v1648835047/ankespa/IMG/Weiiz_Spa_6_yuwh9q.png'
  },
  { 
    title:'配套服务', 
    content:'我们研究开发了自己的预约App，用户一键预约，商家告别传统电话预约模式', 
    image:'https://res.cloudinary.com/dlapk94rx/image/upload/v1648835047/ankespa/IMG/Weiiz_Spa_2_tmkv7q.png'
  },
  { 
    title:'营销推广', 
    content:'我们有专业的营销推广团队帮助您最快的吸引用户到您的店里体验各种服务', 
    image:'https://res.cloudinary.com/dlapk94rx/image/upload/v1648835047/ankespa/IMG/Weiiz_Spa_5_ycjij8.png'
  },
  { 
    title:'硬件支持', 
    content:'我们独家代理的pos机以及其它收银系统，保证行业最低手续费', 
    image:'https://res.cloudinary.com/dlapk94rx/image/upload/v1648835046/ankespa/IMG/Weiiz_Spa_1_h3uoco.png'
  },
]

export default function Good({phone}) {
  return (

    <div className="good-container">
        <Typography variant="h4" id="good">
        特色与优势
        </Typography>

        <Cards phone={phone} data={data}/>
    </div>

  )
}
