import React,{useEffect,useState} from 'react'
import Images from './Components/Images/index'
import Nav from './Components/Nav/index'
import Intro from './Components/Intro/index'
import Content from './Components/Content/index'
import News from './Components/News/index'
import Target from './Components/Target/index'
import Resource from './Components/Resource/index'
import Good from './Components/Good/index'
import Footer from './Components/Footer/index'
import Contact from './Components/Contact/index'
import './App.css'
function App() {

  const [phone,setIsPhone] = useState(false)
  useEffect(()=>{
      const width = window.innerWidth
      if(width>500){
          setIsPhone(false)
      }
      else{
          setIsPhone(true)
      }
    },[])
  return (
    <div className="App">
    <Nav/>
    <Images/>
    <div className="app-wrap">
        <Intro/>
        <Good phone={phone}/>
        <Content/>
        <News/>
        <Target/>
        <Resource phone={phone}/>
        <Contact phone={phone}/>
    </div>
    <Footer/>
    </div>
  );
}

export default App;
