import React from 'react'
import { Typography } from '@mui/material';
import {Timeline,TimelineItem,TimelineSeparator,TimelineConnector,TimelineContent,TimelineDot,TimelineOppositeContent } from '@mui/lab'
import './index.scss'
export default function Target() {
  return (

    <div className="target-container">
        <Typography variant="h4" id="target">
        代理流程
        </Typography>

        <Timeline position="alternate">
        <TimelineItem>
          <TimelineOppositeContent >
            第一步
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>参加说明会</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent >
            洽谈加盟理念
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>第二步</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent >
            第三步
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>签订加盟合约</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent >
            寻找店面
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>第四步</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent >
          第五步
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>确定营业地点</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent >
          确定装修风格
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>第六步</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent >
          第七步
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>装修/验收</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent >
          开业
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>第八步</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent >
          第九步
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            
          </TimelineSeparator>
          <TimelineContent>推广营销</TimelineContent>
        </TimelineItem>
      </Timeline>
    </div>

  )
}
