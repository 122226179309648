import React from 'react'
import { Typography,Box ,Paper,styled} from '@mui/material';
import {Masonry} from '@mui/lab'
import './index.scss'
export default function Resource({phone}) {


  const Label = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#1A2027',
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: 'center',
    color: '#fff',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  }));
  const itemData = [
    {
      img: 'https://res.cloudinary.com/dlapk94rx/image/upload/v1645823915/ankespa/red_lantern_foot_spa_cimm0v.jpg',
      title: 'Red Lantern Foot Spa',
    },
    {
      img: 'https://res.cloudinary.com/dlapk94rx/image/upload/v1645823916/ankespa/WechatIMG5453_en8ove.jpg',
      title: 'Massage Palace',
    },
    {
      img: 'https://res.cloudinary.com/dlapk94rx/image/upload/v1645823916/ankespa/WechatIMG5455_logifv.jpg',
      title: 'Bodyworks Station',
    },
    {
      img: 'https://res.cloudinary.com/dlapk94rx/image/upload/v1645823915/ankespa/WechatIMG5457_rzfezq.jpg',
      title: '名媛国际',
    },
    {
      img: 'https://res.cloudinary.com/dlapk94rx/image/upload/v1645823915/ankespa/WechatIMG5459_yl9gor.jpg',
      title: 'W Foot Spa',
    },
    {
      img: 'https://res.cloudinary.com/dlapk94rx/image/upload/v1645823915/ankespa/WechatIMG5461_zwngov.jpg',
      title: 'Gold Leaf Foot Spa',
    },
    {
      img: 'https://res.cloudinary.com/dlapk94rx/image/upload/v1644959341/magic%20finger%20spa/WechatIMG7_ctmkd5.jpg',
      title: 'Magic Fingers Spa',
    },
    {
      img: 'https://res.cloudinary.com/dlapk94rx/image/upload/v1645824447/ankespa/%E6%88%AA%E5%B1%8F2022-02-25_%E4%B8%8B%E5%8D%884.27.00_l1lsju.png',
      title: 'Eyelash Station',
    },
    {
      img: 'https://res.cloudinary.com/dlapk94rx/image/upload/v1644959341/magic%20finger%20spa/WechatIMG16_em7yjp.jpg',
      title: 'Moon Spa',
    },
    {
      img: 'https://res.cloudinary.com/dlapk94rx/image/upload/v1603921307/massage%20palace/DSC03942_zzsgqh.jpg',
      title: 'Deep Relax Spa',
    },
    {
      img: 'https://res.cloudinary.com/dlapk94rx/image/upload/v1645635657/ankespa/222_renf4r.jpg',
      title: 'Hot Stone Massage',
    },
    {
      img: 'https://res.cloudinary.com/dlapk94rx/image/upload/v1645635657/ankespa/111_ykxiyj.jpg',
      title: 'King Spa',
    },
   
  ];

  return (

    <div className="resource-container">
        <Typography variant="h4" id="resource">
        总部资源
        </Typography>
          <hr />
        <Typography variant="subtitle1" >
            专业的装修团队
            <br />
            专业的营销团队
            <br />
            合作的地产经纪团队
            <br />
            合作的信用卡公司
        </Typography>
          <hr />
        <Typography  variant='h5'>
          合作伙伴
        </Typography>

        <Box className="resource-h5" sx={{ width: phone? 350: '100%', minHeight: 529 }}>
      <Masonry columns={3} spacing={2}>
        {itemData.map((item, index) => (
          <div key={index}>
            <Label>{item.title}</Label>
            <img
              src={`${item.img}?w=162&auto=format`}
              srcSet={`${item.img}?w=162&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
              style={{
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
                display: 'block',
                width: '100%',
              }}
            />
          </div>
        ))}
      </Masonry>
    </Box>
    </div>

  )
}

