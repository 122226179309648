import React from 'react'
import {Card, CardActions,CardContent,CardMedia,Typography,Button} from '@mui/material'
import './index.scss'
export default function Cards({phone,data}) {
  return (

    <div className={phone ? "goods-container1" : "goods-container"}>
        {
            data.map((v,i)=>(
                <Card className={phone ? "card" : "card1"} key={i} >
                <CardMedia
                  component="img"
                  height="140"
                  image={v.image}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {v.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {v.content}
                  </Typography>
                </CardContent>
                {/* <CardActions>
                  <Button size="small">Share</Button>
                  <Button size="small">Learn More</Button>
                </CardActions> */}
              </Card>
            ))
        }
       
    </div>
  )
}
