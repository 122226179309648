import React from 'react'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './index.scss'
const gallery = [
    {
        image:'https://res.cloudinary.com/dlapk94rx/image/upload/v1648835367/ankespa/IMG/Weiiz_Spa_1_h9aflq.png',
        title:'',
    },
    {
        image:'https://res.cloudinary.com/dlapk94rx/image/upload/v1648835366/ankespa/IMG/Weiiz_Spa_2_jx64bi.png',
        title:'',
    },
    {
        image:'https://res.cloudinary.com/dlapk94rx/image/upload/v1648835367/ankespa/IMG/Weiiz_Spa_e0x6vc.png',
        title:'',
    },
    // {
    //     image:'',
    //     title:'',
    // },
    // {
    //     image:'',
    //     title:'',
    // },
]

export default function Images() {

  return (
    <div > 
        
        
        <Carousel autoPlay dynamicHeight showThumbs={false} showStatus={false} infiniteLoop>

                    {
                        gallery&&gallery.map((v,i)=>(
                            <div key={i}>
                                <img className="carImg" src={v.image} alt="" />
                                {/* <p className="legend">{i}</p> */}
                            </div>
                        ))
                    }
        </Carousel>



    </div>
  )
}
