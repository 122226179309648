import React from 'react'
import { Typography } from '@mui/material';
import './index.scss'
export default function Content() {
  return (

    <div className="content-container">
        <Typography variant="h4" id="content">
        营业内容
        </Typography>

        <Typography variant="subtitle1" >
            除了传统按摩行业的相关服务：瑞士按摩 泰式按摩 台式按摩 指压按摩等，我们独家推出了科技结合按摩的新颖模式（详情可咨询）
            <br />
           结合中国传统的养生模式
            <br />
            搭配独有的按摩设备，让顾客满意，让技师省心
        </Typography>
    </div>

  )
}