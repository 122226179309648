import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from "react-simple-maps";

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

  const markers = [
    {
      markerOffset: 15,
      name: "Stamford",
      coordinates: [-73.56634270992689,41.05790577675682 ]
    },
    { markerOffset: 15, name: "New Haven", coordinates: [ -72.94561522291522,41.330715976267015] },
    { markerOffset: 15, name: "Kingston", coordinates: [ -72.94561522291522,41.330715976267015] },
    { markerOffset: 15, name: "Long Island", coordinates: [-74.01128898583156,41.93629123128943] },
    { markerOffset: 15, name: "Flemington", coordinates: [-73.19830076862922,40.83177413768323]},
    { markerOffset: 15, name: "Philadelphia",  coordinates: [-75.15661366867563,39.94479146074105] },
    { markerOffset: -30, name: "Pennsylvania", coordinates: [ -77.7850807420983,40.851204924208766] },
    { markerOffset: -30, name: "Atlantic City", coordinates: [-74.56183418247129,39.323520523321136 ] },
    { markerOffset: 15, name: "Baltimore", coordinates: [-76.65526274580441,39.41413723386277]  },
    { markerOffset: 15, name: "Atlanta", coordinates: [ -84.36358700105718,33.73643579940844] },
    { markerOffset: 15, name: "Orlando", coordinates: [-81.43253637620246,28.542874493794212]  },
    { markerOffset: 15, name: "Tampa", coordinates: [-82.40381331601405,27.974814259866168 ] }
  ];

  const markers1 = [
    {
     
      coordinates: [-73.56634270992689,41.05790577675682 ]
    },
    {  coordinates: [ -72.94561522291522,41.330715976267015] },
    { coordinates: [-74.01128898583156,41.93629123128943] },
    {  coordinates: [-73.19830076862922,40.83177413768323] },
    {  coordinates: [-74.85174306727032,40.51514278349319] },
    {  coordinates: [-75.15661366867563,39.94479146074105] },
    {  coordinates: [ -77.7850807420983,40.851204924208766] },
    {  coordinates: [-74.56183418247129,39.323520523321136 ] },
    {  coordinates: [-76.65526274580441,39.41413723386277] },
    {  coordinates: [ -84.36358700105718,33.73643579940844] },
    {  coordinates: [-81.43253637620246,28.542874493794212] },
    {  coordinates: [-82.40381331601405,27.974814259866168 ] }
  ];

const MapChart = () => {
  return (
    <ComposableMap  projection="geoAlbers">
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map(geo => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              fill="#DDD"
              stroke="#FFF"
            />
          ))
        }
      </Geographies>
      {/* <Marker coordinates={[-74.006, 40.7128]}>
        <circle r={8} fill="#F53" />
      </Marker>
      <Marker coordinates={[-82.40381331601405,27.974814259866168 ]}>
        <circle r={8} fill="#F53" />
      </Marker> */}
    {markers.map(({ name, coordinates, markerOffset }) => (
        <Marker key={name} coordinates={coordinates}>
          <g
            fill="none"
            stroke="#FF5533"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-12, -24)"
          >
            <circle cx="12" cy="10" r="3" />
            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
          </g>
          {/* <text
            textAnchor="middle"
            y={markerOffset}
            style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
          >
            {name}
          </text> */}
        </Marker>
      ))}
    </ComposableMap>
  );
};

export default MapChart;